import React, { FC } from "react";
import Info from "./Info";

interface Props {
  count: number;
}
//depending on the count passed into it, this component updates the style of the text it displays.
//Once the opening typing animation ends, it informs the Info component to show the rest of the info.
const MainTitle: FC<Props> = ({ count }) => {
  const title = ` SOFTWARE-HANDS`;
  const firstText = title.slice(0, count + 1);
  const secondText = title.slice(count + 1);
  const showContents = secondText ? true : false;

  return (
    <div className="flex-grow flex flex-col items-center justify-around">
      <div className="flex items-center justify-center md:text-3xl">
        {<div className="titleBlack">{firstText}</div>}
        {<div className="titleGrey">{secondText}</div>}
      </div>
      <div className="flex flex-col items-center justify-center">
        <Info showContents={showContents} />
      </div>
    </div>
  );
};

export default MainTitle;
