import React, { FC } from "react";
interface Props {
  letter: string;
}

//displays the image passed into it from parent.
const Computer: FC<Props> = ({ letter }) => {
  return (
    <div className="mt-auto">
      <img src={letter} alt="computer" className="1-full b-bottom" />
    </div>
  );
};

export default Computer;
