import React, { FC, useState } from "react";
type Props = {
  showContents: boolean;
};

//displays content and changes style when showContents is true.
const Info: FC<Props> = ({ showContents }) => {
  const [showFirstLine, setShowFirstLine] = useState(false);
  const [showSecondLine, setShowSecondLine] = useState(false);

  if (!showContents) {
    setTimeout(() => {
      setShowFirstLine(true);
    }, 1000);
    setTimeout(() => {
      setShowSecondLine(true);
    }, 2500);
  }

  return (
    <>
      <p
        className={`infoText ${
          showFirstLine ? "text-gray-600" : " text-white"
        }`}
      >
        Full Stack Development
      </p>
      <p
        className={`infoText ${
          showSecondLine ? "text-blue-500" : "text-white"
        }`}
      >
        <a href="mailto:jake@softwarehands.io">jake@softwarehands.io</a>
      </p>
    </>
  );
};

export default Info;
