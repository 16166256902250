import React, { useState, useEffect } from "react";
import MainTitle from "./MainTitle";
import Computer from "./Computer";

// import letterSHover = "./images/letterS2.jpg";
// import letterS = "./images/letterS.jpg";
// import letterOHover from "./images/letterO.jpg";
// import letterO from "./images/letterO2.jpg";
// import letterFHover from "./images/letterF.jpg";
// import letterF from "./images/letterF2.jpg";
// import letterTHover from "./images/letterT.jpg";
// import letterT from "./images/letterT2.jpg";
// import letterWHover from "./images/letterW.jpg";
// import letterW from "./images/letterW2.jpg";
// import letterAHover from "./images/letterA.jpg";
// import letterA from "./images/letterA2.jpg";
// import letterRHover from "./images/letterR.jpg";
// import letterR from "./images/letterR2.jpg";
// import letterEHover from "./images/letterE.jpg";
// import letterE from "./images/letterE2.jpg";
// import letterHHover from "./images/letterH.jpg";
// import letterH from "./images/letterH2.jpg";
// import letterNHover from "./images/letterN.jpg";
// import letterN from "./images/letterN2.jpg";
// import letterDHover from "./images/letterD.jpg";
// import letterD from "./images/letterD2.jpg";
// import letterSpaceHover from "./images/spacebar.jpg";
// import letterSpace from "./images/spacebar2.jpg";

function App() {
  const letterSHover = "./images/letterS2.jpg";
  const letterS = "./images/letterS.jpg";
  const letterOHover = "./images/letterO.jpg";
  const letterO = "./images/letterO2.jpg";
  const letterFHover = "./images/letterF.jpg";
  const letterF = "./images/letterF2.jpg";
  const letterTHover = "./images/letterT.jpg";
  const letterT = "./images/letterT2.jpg";
  const letterWHover = "./images/letterW.jpg";
  const letterW = "./images/letterW2.jpg";
  const letterAHover = "./images/letterA.jpg";
  const letterA = "./images/letterA2.jpg";
  const letterRHover = "./images/letterR.jpg";
  const letterR = "./images/letterR2.jpg";
  const letterEHover = "./images/letterE.jpg";
  const letterE = "./images/letterE2.jpg";
  const letterHHover = "./images/letterH.jpg";
  const letterH = "./images/letterH2.jpg";
  const letterNHover = "./images/letterN.jpg";
  const letterN = "./images/letterN2.jpg";
  const letterDHover = "./images/letterD.jpg";
  const letterD = "./images/letterD2.jpg";
  const letterSpaceHover = "./images/spacebar.jpg";
  const letterSpace = "./images/spacebar2.jpg";
  const [currentLetterSource, setCurrentLetterSource] = useState(letterFHover);
  const [count, setCount] = useState(0);
  const [letterArr, setLetterArr] = useState<string[]>([
    letterSHover,
    letterS,
    letterOHover,
    letterO,
    letterFHover,
    letterF,
    letterTHover,
    letterT,
    letterWHover,
    letterW,
    letterAHover,
    letterA,
    letterRHover,
    letterR,
    letterEHover,
    letterE,
    letterSpaceHover,
    letterSpace,
    letterHHover,
    letterH,
    letterAHover,
    letterA,
    letterNHover,
    letterN,
    letterDHover,
    letterD,
    letterSHover,
    letterS,
    letterFHover,
  ]);

  //useEffect is continually called until the letterArr array is empty, each time changing the image for the Computer component and updating the style to the Title component
  useEffect(() => {
    if (!letterArr.length) setCurrentLetterSource(letterFHover);
    else {
      const arrCopy = [...letterArr];
      const time = letterArr.length % 2 === 0 ? 200 : 350;
      setTimeout(() => {
        setCurrentLetterSource(arrCopy[0]);
        setLetterArr([...arrCopy.slice(1)]);
        setCount(count + 0.5);
      }, time);
    }
  }, [letterArr, setLetterArr, setCurrentLetterSource, count]);

  return (
    <div className="h-screen w-screen md:bg-black">
      <div className="h-full flex flex-col justify-between max-w-screen-sm mx-auto md:border-r-2 md:border-l-2 md:inset-4 bg-white">
        <MainTitle count={count} />
        <Computer letter={currentLetterSource} />
      </div>
    </div>
  );
}

export default App;
